.customTable {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #dddddd;
  }
  
  .customTable th, .customTable td {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  }
  
  .customTable th {
    background-color: #f2f2f2;
  }
  
  .customTable tr:hover {
    background-color: #f5f5f5;
  }
  
  .customTable button {
    padding: 5px 10px;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .customTable button:hover {
    background-color: #0056b3;
  }
  