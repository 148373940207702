.deleted {
    width: 100%;
    height: 100vh;
}

.deleted a {
    font-size: 20px;
    padding: 10px 30px;
    margin-top: 30px;
    background-color: ghostwhite;
}