.landing-banner {
    height: 100vh;
    width: 100%;
}

.about-h2 {
    padding: 10px 0;
}

.banner-container {
    width: 70%;
}


.topnav .icon {
    display: none;
}

@media screen and (max-width: 768px) {
    .about-user {
        display: flex !important;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav .icon {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        flex-direction: column;
    }

    .topnav.responsive .icon {
        position: absolute;
        top: 0;
        right: 0;
    }

    .topnav.responsive a {
        display: block;
        text-align: center;
    }
}

.visuals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.visuals img {
    flex-basis: calc(33.33% - 10px);
    margin-bottom: 10px;
}

.testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.testimonial {
    background-color: #f9f9f9;
    border-left: 10px solid #ccc;
    margin-bottom: 20px;
    padding: 20px;
}

.testimonial p {
    font-style: italic;
    margin-bottom: 10px;
}

.testimonial .author {
    font-weight: bold;
}


.topnav {
    background-color: #333;
    overflow: hidden;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}


.topnav a.active {
    background-color: #2196F3;
    color: white;
}


.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

.column {
    float: left;
    width: calc(50% - 20px);
    height: 300px;
    padding: 10px;
    box-sizing: border-box;
    margin: 2px;
}

.column-one {
    padding-left: 40px;
}

.column-two {
    padding-left: 120px;
    padding-right: -10px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.text {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin: 0;
}

.rounded-image {
    border-radius: 10%;
    width: 100%;
    height: auto;
}

.row-one {
    padding-top: 70px;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
}

/* Developers Section */
.grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
}

.grid>div {
    text-align: center;
}

.grid img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.landing-banner {
    height: 100vh;
    width: 100%;
}

.banner-container {
    width: 70%;
}



.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav .icon {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        flex-direction: column;
    }

    .topnav.responsive .icon {
        position: absolute;
        top: 0;
        right: 0;
    }

    .topnav.responsive a {
        display: block;
        text-align: center;
    }
}

.visuals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.visuals img {
    flex-basis: calc(33.33% - 10px);
    margin-bottom: 10px;
}

.testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.testimonial {
    background-color: #f9f9f9;
    border-left: 10px solid #ccc;
    margin-bottom: 20px;
    padding: 20px;
}

.testimonial p {
    font-style: italic;
    margin-bottom: 10px;
}

.testimonial .author {
    font-weight: bold;
}

/* Footer styles */
footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
}

footer i {
    color: #fff;
}

footer p {
    margin: 0;
}

:root {
    --primary: #2196F3;
    --secondary: #FFC107;
    --bg: #f5f5f5;
}

.topnav {
    background-color: #333;
    overflow: hidden;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}


.topnav a.active {
    background-color: #2196F3;
    color: white;
}


.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

.column {
    float: left;
    width: calc(50% - 20px);
    height: 300px;
    padding: 10px;
    box-sizing: border-box;
    margin: 2px;
}

.column-one {
    padding-left: 40px;
}

.column-two {
    padding-left: 120px;
    padding-right: -10px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.text {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin: 0;
}

.rounded-image {
    border-radius: 10%;
    width: 100%;
    height: auto;
}

.row-one {
    padding-top: 70px;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
}