.ChatBotContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    margin: 20px;
}

.botIcon {
    width: 60px;
    height: 60px;
}

.chatbot-opener-icon {
    justify-content: flex-end;
}

.chatbot-active {
    display: flex !important;
}

.chatbot {
    display: none;
    width: 350px;
    height: 500px;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.chatbot-header {
    width: 100%;
    height: 40px;
    color: #fff;
    padding: 0 10px;
    align-items: center;
    text-transform: lowercase;
    background-color: #6495ED;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.chatbot-message-container {
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: scroll;
    padding: 10px;
}

.chatbot-message-container::-webkit-scrollbar {
    width: 8px;
}

.chatbot-message-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chatbot-message-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.chatbot-message-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.chatbot-send-message,
.chatbot-receive-message{
    width: 100%;
    margin: 10px 0;
    border-radius: 10px;
}

.chatbot-send-message {
    text-align: right;
    justify-content: flex-end;
    /* background-color: #007BFF !important; */
    color: black;
    width: 100%;
}
.chatbot-receive-message{
    text-align: left;
    justify-content: flex-start;
    background-color: #f8f9fa;
}

.chatbot-message{
    width: 90%;
    padding: 10px;
    background-color: gainsboro;
    border-radius: 10px;
}

.bot-input-form{
    width: 100%;
    bottom: 0px;
    position: absolute;
}

.bot-input {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 16px;
    padding: 0 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}