@import url('https: //cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
@media screen and (max-width: 600px) {

    .nav-container {
        flex-direction: column;
    }

    .nav-links-container {
        margin-top: 10px;
    }

    .nav-item-links {
        flex-direction: column;
    }

    .nav-link {
        margin: 5px 0;
    }

    #container {
        height: 200px;
    }

    #centered-logo {
        width: 80%;
    }

    .card {
        padding: 10px;
    }

    .vertical-listing-header {
        font-size: 1.2rem;
    }

}




.row {
    display: flex;
}

.nav-container {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.nav-item {
    font-size: 1.5rem;
    color: var(--primary);
}

.nav-links-container {
    width: 60%;
}

.nav-item-links {
    justify-content: space-around;
    /* align-items: center; */
}

.nav-link {
    text-decoration: none;
    color: var(--secondary);

}

#container {
    position: relative;
    text-align: center;
    height: 800px;
}

#video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#centered-logo {
    position: relative;
    width: 400px;
}

main {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.card {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
}

.vertical-listing-header {
    font-size: 1.5rem;
    color: var(--primary);
}

footer {
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    padding: 10px;
}

.social-icons a {
    color: var(--secondary);
}

.social-icons a:hover {
    color: #fff;
}

.topnav {
    overflow: hidden;
    background-color: #333;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav a.active {
    background-color: var(--primary);
    color: white;
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

.photo-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
}

.photo-card {
    width: 300px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.photo-card img {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
}

.story-carrier-story {
    align-items: flex-end;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    cursor: pointer;
    display: block;
    filter: grayscale(100%);
    height: 450px;
    margin-top: 30px;
    overflow: visible;
    position: relative;
    width: 100%;
    z-index: 0;
    transition: all 0.3s ease;
}

.uta-lazy-bg {
    background-color: #0064b1;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition-duration: .2s;
    transition-property: opacity;
}

.uta-lazy-loaded {
    opacity: 1;
    z-index: 0;
}


.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.password-reset-card {
    width: 300px;
    padding: 20px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.password-reset-card form {
    display: flex;
    flex-direction: column;
}

.password-reset-card input[type="email"] {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.password-reset-card input[type="submit"] {
    padding: 10px;
    background-color: #2196F3;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.password-reset-card input[type="submit"]:hover {
    background-color: grey;
}

footer {
    margin-top: auto;
}

/* Style the container/contact section */
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 10px;
}

/* Create two columns that float next to eachother */
.column {
    float: left;
    width: 50%;
    margin-top: 6px;
    padding: 20px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

    .column,
    input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}




/* Style the container/contact section */
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 10px;
}

/* Create two columns that float next to eachother */
.column {
    float: left;
    width: 50%;
    margin-top: 6px;
    padding: 20px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

    .column,
    input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}



/* Merged from auth.css */
.auth-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    /* background-color: coral; */
    /* justify-content: center; */
}

.auth-nav {
    width: 80%;
    height: 80px;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
}

.auth-item-links {
    flex: .8;
    justify-content: space-between;
}

.auth-nav-link {
    /* color: #fff; */
    border-radius: 20px;
    padding: 10px 30px;
    background-color: gainsboro;
}

.auth-form-header {
    margin-bottom: 40px;
}

.auth-form-container {
    width: 50%;
    height: fit-content;
    padding: 50px 40px;
    margin-top: 70px;
    background-color: gainsboro;
}

.input-core {
    background-color: #fff;
}

@media screen and (max-width: 428px) {
    .auth-nav {
        height: fit-content !important;
        flex-direction: column !important;
    }

    .auth-item-links {
        flex-direction: column !important;
        width: 100%;
    }

    .auth-nav-link {
        margin: 10px 0;
    }

    .auth-container {
        flex-direction: column;
    }

    .auth-form-container {
        width: 90%;
        height: fit-content;
        padding: 50px 20px;
        margin-top: 70px;
        background-color: gainsboro;
    }

    .dbl {
        flex-direction: column !important;
    }

    .dbl .input-core {
        width: 100% !important;
    }

    .dbl .input-core .input {
        width: 100%;
    }
}

/* Merged from signup.css */
.tos-link {
    color: blue;
}

.tos-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#tos-label {
    padding-left: 10px;
}

.landing-banner {
    height: 100vh;
    width: 100%;
}

.banner-container {
    width: 70%;
}




.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav .icon {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        flex-direction: column;
    }

    .topnav.responsive .icon {
        position: absolute;
        top: 0;
        right: 0;
    }

    .topnav.responsive a {
        display: block;
        text-align: center;
    }
}


.visuals {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.visuals img {
    flex-basis: calc(33.33% - 10px);
    margin-bottom: 10px;
}

.testimonials {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.testimonial {
    background-color: #f9f9f9;
    border-left: 10px solid #ccc;
    margin-bottom: 20px;
    padding: 20px;
}

.testimonial p {
    font-style: italic;
    margin-bottom: 10px;
}

.testimonial .author {
    font-weight: bold;
}

/* Footer styles */
footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
}

footer i {
    color: #fff;
}

footer p {
    margin: 0;
}

:root {
    --primary: #2196F3;
    --secondary: #FFC107;
    --bg: #f5f5f5;
}

.topnav {
    background-color: #333;
    overflow: hidden;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}


.topnav a.active {
    background-color: #2196F3;
    color: white;
}


.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive a.icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}

.column {
    float: left;
    width: calc(50% - 20px);
    height: 300px;
    padding: 10px;
    box-sizing: border-box;
    margin: 2px;
}

.column-one {
    padding-left: 40px;
}

.column-two {
    padding-left: 120px;
    padding-right: -10px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.text {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin: 0;
}

.rounded-image {
    border-radius: 10%;
    width: 100%;
    height: auto;
}

.row-one {
    padding-top: 70px;
}

.flip-card {
    background-color: transparent;
    width: 300px;
    height: 200px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: dodgerblue;
    color: white;
    transform: rotateY(180deg);
}
:root {
    --primary: #2196F3;
    --secondary: #FFC107;
    --bg: #f5f5f5;
}

/* Styles for screens smaller than 600px */
@media screen and (max-width: 600px) {

    .nav-container {
        flex-direction: column;
    }

    .nav-links-container {
        margin-top: 10px;
    }

    .nav-item-links {
        flex-direction: column;
    }

    .nav-link {
        margin: 5px 0;
    }

    #container {
        height: 200px;
    }

    #centered-logo {
        width: 80%;
    }

    .card {
        padding: 10px;
    }

    .vertical-listing-header {
        font-size: 1.2rem;
    }

}


.row {
    display: flex;
}

.nav-container {
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.nav-item {
    font-size: 1.5rem;
    color: var(--primary);
}

.nav-links-container {
    width: 60%;
}

.nav-item-links {
    justify-content: space-around;
    /* align-items: center; */
}

.nav-link {
    text-decoration: none;
    color: var(--secondary);

}

#container {
    position: relative;
    text-align: center;
    height: 400px;
}

#video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#centered-logo {
    position: relative;
    width: 400px;
}

.card {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
}

.vertical-listing-header {
    font-size: 1.5rem;
    color: var(--primary);
}

footer {
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    padding: 10px;
}

.social-icons a {
    color: var(--secondary);
}

.social-icons a:hover {
    color: #fff;
}

.topnav {
    overflow: hidden;
    background-color: #333;
}

.topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.topnav a:hover {
    background-color: #ddd;
    color: black;
}

.topnav a.active {
    background-color: var(--primary);
    color: white;
}

.topnav .icon {
    display: none;
}

@media screen and (max-width: 600px) {
    .topnav a:not(:first-child) {
        display: none;
    }

    .topnav a.icon {
        float: right;
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
        position: absolute;
        right: 0;
        top: 0;
    }

    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
}