.student-card{
    width: 100%;
    padding: 20px 0;
}

.sc-text-item{
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.student-external-link{
    width: 300px;
    justify-content: space-between;
}