.course-card {
    width: 400px;
    height: 350px;
    border-radius: 10px;
    margin: 10px 20px;
    background-color: #dccfff;
}

.cc-img-item {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.course-banner{
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cc-text-item {
    width: 100%;
    height: 150px;
    padding: 10px 15px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}
.course-external-link{
    width: 100%;
    justify-content: space-between;
}

.course-tag {
    width: fit-content;
    height: fit-content;
    display: inline-block;
    color: #fff;
    padding: 10px 30px;
    border-radius: 15px;
    background-color: var(--blue);
}

@media screen and (max-width: 428px) {
    .course-card {
        width: 100%;
        height: 350px;
        margin: 10px 0;
    }

    .cc-img-item {
        height: 150px;
    }

    .cc-text-item {
        height: 200px;
    }
}

@media screen and (max-width: 884px) {
    .courses-container {
        flex-wrap: wrap;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around !important;
    }

    .course-card {
        width: 300px;
        height: 350px;
        margin: 10px;
    }
}