.dashboard-upper {
    flex: 1;
    height: 80px;
    width: 100%;
    padding: 0 20px;
    align-items: center;
    color: #ffffff;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #6495ED;
}

.upper-link {
    margin: 0 20px;
    color: #ffffff;
    padding: 10px 20px;
}

.upper-mobile-nav{
    display: none;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.nav-btn{
    color: #6495ED;
    border-radius: 10px;
    background-color: #ffffff;
}

@media screen and (max-width: 768px){
    .dashboard-upper{
        width: 100%;
        justify-content: space-between;
    }
    .upper-link-item{
        display: none;
    }
    .upper-mobile-nav{
        display: flex;

    }
}
