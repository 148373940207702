.course-details-cont {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.course-details-img {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.course-timing {
    width: 50%;
    padding: 20px 0;
    justify-content: space-between;
}

.aside-course-nav {
    width: fit-content;
    height: 100%;
    margin: 0 10px;
    padding: 10px 30px;
}

.aside-course-nav a {
    margin: 10px 0;
}

.course-card {
    height: fit-content;
    margin: 20px 0;
}

.cc-text-item {
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .dashboard-main-item {
        flex-direction: column !important;
    }

    .course-details-img {
        height: 200px ;
    }

    .aside-course-nav {
        width: 100%;
        height: fit-content;
        padding: 10px 0;
    }

    .aside-course-nav a {
        padding: 10px;
        margin: 10px 0;
        background-color: gainsboro;
    }
}