.auth-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
}

.auth-nav {
    width: 80%;
    height: 80px;
    align-items: center;
    margin-top: 30px;
    justify-content: space-between;
}

.auth-item-links {
    flex: .8;
    justify-content: space-between;
}

.auth-nav-link {
    border-radius: 20px;
    padding: 10px 30px;
    background-color: #007BFF;
    color: #fff;
}

.auth-form-header {
    margin-bottom: 40px;
    color: #007BFF;
}

.auth-form-container {
    width: 50%;
    height: fit-content;
    padding: 50px 40px;
    margin-top: 70px;
    background-color: #e9ebed; /* Light grey background color */
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.input-core {
    background-color: #f8f9fa;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

/* ... Rest of your CSS ... */

.tos-link {
    color: #007BFF;
}

.tos-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#tos-label {
    padding-left: 10px;
}

.signup-btn {
    border-radius: 20px;
    background-color: #007BFF;
    color: #fff;
}