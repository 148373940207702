@import '@radix-ui/colors/black-alpha.css';

.ProgressRoot {
  position: relative;
  overflow: hidden;
  /* background: var(--black-a9); */
  background:lightblue;
  
  border-radius: 99999px;
  width: 300px;
  height: 25px;

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: translateZ(0);
}

.ProgressIndicator {
  background-color: #BAA7FF;
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}