.admin-faculty-card {
    width: 100%;
    padding: 20px 0;
}

.sc-text-item {
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.faculty-external-link {
    width: 300px;
    justify-content: space-between;
}
/* AdminFacultyCard.css */

.admin-faculty-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .admin-faculty-card:hover {
    transform: translateY(-3px);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .faculty-banner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .faculty-text-item {
    flex: 1;
    margin-left: 20px;
  }
  
  .faculty-text-item h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .faculty-text-item span {
    font-size: 1rem;
    color: #666;
  }
  
  .faculty-external-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }
  
  .faculty-tag {
    margin-left: 10px;
    font-size: 0.9rem;
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease-in-out;
  }
  
  .faculty-tag:hover {
    color: #0056b3;
  }
  

@media screen and (max-width: 768px) {
    .sc-text-item {
        flex-wrap: wrap;
    }

    .faculty-external-link {
        width: 100%;
        margin-top: 10px;
        justify-content: space-between;
    }
}