.message-container {
    width: 100%;
    height: 570px;
    position: relative;
    background-color: aliceblue;
}

.message-header {
    width: 100%;
    height: 50px;
    color: #fff;
    padding: 0 20px;
    align-items: center;
    background-color: green;
}

.message-item-container {
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
}

.receive-message,
.send-message {
    width: 100%;
    margin: 10px 0;
    height: fit-content;
    justify-content: flex-end;
    /* background-color: gainsboro; */
}

.receive-message {
    justify-content: flex-start;
}

.send-item,
.receive-item {
    width: fit-content;
    max-width: 90%;
    padding: 10px 20px;
    font-size: 18px;
    background-color: gainsboro;
}

.receive-item {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.send-item {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.message-form {
    width: 100%;
    height: 50px;
    display: flex;
    position: absolute;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    background-color: gainsboro;
}

.message-form input::placeholder {
    color: #000;
}