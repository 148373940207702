.input2 {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
    margin-bottom: 10px;
    border: 1px solid gray;
}

.textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid gray;
}
.contact-main{
    height: calc(100vh - 80px);
}
@media screen and (max-width: 768px) {
    .contact-internal{
        flex-direction: column !important;
    }
    .contact-internal1{
        width: 100%;
        height: fit-content;
        padding: 20px 0;
    }
    .contact-internal2{
        width: 100%;
        height: fit-content;
        padding: 20px;
    }
}