.user-profile-dashboard {
    width: 100%;
}

.user-profile-img {
    width: 150px;
    height: 150px;
}

.user-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.user-profile-info {
    flex: .6;
    padding-left: 30px;
    align-items: center;
    justify-content: center;
}